var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-qa-test" },
    [
      _c("div", { staticClass: "page-body" }, [
        _c("div", { staticClass: "header" }, [
          _c("img", {
            attrs: { src: _vm.ossHost + "img/icon-tl-box.jpg", alt: "" }
          }),
          _c("div", { staticClass: "device-num" }, [
            _vm._v(_vm._s(_vm.uniqueId))
          ]),
          _c("div", { staticClass: "device-type" }, [
            _vm._v(_vm._s(_vm.isFs ? "风送投料机控制盒" : "二代投料机控制盒"))
          ])
        ]),
        _vm.qaType != 2
          ? _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "content-title" }, [
                _vm._v("一.功能质检：")
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("1.设备正常联网")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _vm.loading && !_vm.info.online
                      ? _c("Loading", {
                          attrs: {
                            size: "24",
                            type: "spinner",
                            color: _vm.themeColor
                          }
                        })
                      : _vm._e(),
                    !_vm.loaded
                      ? _c("span", { staticClass: "disable" }, [
                          _vm._v("未检测")
                        ])
                      : _vm._e(),
                    !_vm.info.online && !_vm.loading
                      ? _c("Icon", { attrs: { name: "close" } })
                      : _vm._e(),
                    _vm.info.online
                      ? _c("Icon", { attrs: { name: "passed" } })
                      : _vm._e(),
                    !_vm.info.online && !_vm.loading
                      ? _c(
                          "Button",
                          {
                            attrs: { color: _vm.themeColor, size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.loadDeviceInfo("online")
                              }
                            }
                          },
                          [_vm._v("重试")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              !_vm.isFs
                ? _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "content-item-label" }, [
                      _vm._v("2.称重读数正常")
                    ]),
                    _c(
                      "div",
                      { staticClass: "content-item-value" },
                      [
                        _vm.startCheck && !_vm.info.weighReadStatus
                          ? _c("Loading", {
                              attrs: {
                                size: "24",
                                type: "spinner",
                                color: _vm.themeColor
                              }
                            })
                          : _vm._e(),
                        !_vm.startCheck
                          ? _c("span", { staticClass: "disable" }, [
                              _vm._v("未检测")
                            ])
                          : _vm._e(),
                        _vm.info.weighReadStatus == 2 && _vm.startCheck
                          ? _c("Icon", { attrs: { name: "close" } })
                          : _vm._e(),
                        _vm.info.weighReadStatus == 1 && _vm.startCheck
                          ? _c("Icon", { attrs: { name: "passed" } })
                          : _vm._e(),
                        _vm.info.weighReadStatus == 2 && _vm.startCheck
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v("未读取到重量")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _c("div", { staticClass: "content-item" }, [
                    _c("div", { staticClass: "content-item-label" }, [
                      _vm._v("2.气压读数正常")
                    ]),
                    _c(
                      "div",
                      { staticClass: "content-item-value" },
                      [
                        _vm.loading && !_vm.info.pressureReadStatus
                          ? _c("Loading", {
                              attrs: {
                                size: "24",
                                type: "spinner",
                                color: _vm.themeColor
                              }
                            })
                          : _vm._e(),
                        !_vm.info.pressureReadStatus && !_vm.loading
                          ? _c("span", { staticClass: "disable" }, [
                              _vm._v("未检测")
                            ])
                          : _vm._e(),
                        _vm.info.pressureReadStatus == 2 && !_vm.loading
                          ? _c("Icon", { attrs: { name: "close" } })
                          : _vm._e(),
                        _vm.info.pressureReadStatus == 1 && !_vm.loading
                          ? _c("Icon", { attrs: { name: "passed" } })
                          : _vm._e(),
                        _vm.info.pressureReadStatus == 2 && !_vm.loading
                          ? _c("span", { staticClass: "error" }, [
                              _vm._v("气压传感器读数异常")
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("3.三个旋钮调整至中间档位")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _vm.startCheck && !_vm.info.motoStatus
                      ? _c("Loading", {
                          attrs: {
                            size: "24",
                            type: "spinner",
                            color: _vm.themeColor
                          }
                        })
                      : _vm._e(),
                    !_vm.startCheck
                      ? _c("span", { staticClass: "disable" }, [
                          _vm._v("未检测")
                        ])
                      : _vm._e(),
                    _vm.info.motoStatus == 2 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "close" } })
                      : _vm._e(),
                    _vm.info.motoStatus == 1 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "passed" } })
                      : _vm._e(),
                    _vm.info.motoStatus == 2 && _vm.startCheck
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("速度,投料,间隔未调至中间")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("4.红外对管已被导通")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _vm.startCheck && !_vm.info.infraredStatus
                      ? _c("Loading", {
                          attrs: {
                            size: "24",
                            type: "spinner",
                            color: _vm.themeColor
                          }
                        })
                      : _vm._e(),
                    !_vm.startCheck
                      ? _c("span", { staticClass: "disable" }, [
                          _vm._v("未检测")
                        ])
                      : _vm._e(),
                    _vm.info.infraredStatus == 2 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "close" } })
                      : _vm._e(),
                    _vm.info.infraredStatus == 1 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "passed" } })
                      : _vm._e(),
                    _vm.info.infraredStatus == 2 && _vm.startCheck
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("未被导通")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("5.扫码质检期间进行开关操作")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _vm.startCheck && !_vm.info.openStatus
                      ? _c("Loading", {
                          attrs: {
                            size: "24",
                            type: "spinner",
                            color: _vm.themeColor
                          }
                        })
                      : _vm._e(),
                    !_vm.startCheck
                      ? _c("span", { staticClass: "disable" }, [
                          _vm._v("未检测")
                        ])
                      : _vm._e(),
                    _vm.info.openStatus == 2 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "close" } })
                      : _vm._e(),
                    _vm.info.openStatus == 1 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "passed" } })
                      : _vm._e(),
                    _vm.info.openStatus == 2 && _vm.startCheck
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("未查询到开关操作")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "content-item",
                  on: {
                    click: function($event) {
                      _vm.zdqCheck = !_vm.zdqCheck
                    }
                  }
                },
                [
                  _c("div", { staticClass: "content-item-label" }, [
                    _vm._v("6.已检查并确认震动器正常工作")
                  ]),
                  _c("div", { staticClass: "content-item-value" }, [
                    _c("span", {
                      class: { "check-item": true, on: _vm.zdqCheck }
                    })
                  ])
                ]
              )
            ])
          : _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "content-title" }, [
                _vm._v("二.称重质检：")
              ]),
              _c("div", { staticClass: "content-item" }, [
                _c("div", { staticClass: "content-item-label" }, [
                  _vm._v("1.称重套装质检")
                ]),
                _c(
                  "div",
                  { staticClass: "content-item-value" },
                  [
                    _vm.startCheck && !_vm.info.weighReadStatus
                      ? _c("Loading", {
                          attrs: {
                            size: "24",
                            type: "spinner",
                            color: _vm.themeColor
                          }
                        })
                      : _vm._e(),
                    !_vm.startCheck
                      ? _c("span", { staticClass: "disable" }, [
                          _vm._v(
                            _vm._s(!_vm.info.online ? _vm.info.msg : "未检测")
                          )
                        ])
                      : _vm._e(),
                    _vm.info.weighReadStatus == 2 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "close" } })
                      : _vm._e(),
                    _vm.info.weighReadStatus == 1 && _vm.startCheck
                      ? _c("Icon", { attrs: { name: "passed" } })
                      : _vm._e(),
                    _vm.info.weighReadStatus == 2 && _vm.startCheck
                      ? _c("span", { staticClass: "error" }, [
                          _vm._v("未读取到重量")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
        _vm.qaType != 2
          ? _c("div", { staticClass: "footer" }, [
              _vm.info.online && _vm.zdqCheck && !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.onclickStartBtn } },
                    [_vm._v(_vm._s(_vm.startCheck ? "重新质检" : "开始检测"))]
                  )
                : _c("div", { staticClass: "btn disabled" }, [
                    _vm._v(
                      _vm._s(
                        _vm.startCheck && _vm.loading ? "检测中..." : "开始检测"
                      )
                    )
                  ])
            ])
          : _c("div", { staticClass: "footer" }, [
              _vm.info.online && _vm.info.weighReadStatus && !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.startCheckAction } },
                    [_vm._v(_vm._s(_vm.startCheck ? "重新质检" : "开始检测"))]
                  )
                : _c("div", { staticClass: "btn disabled" }, [
                    _vm._v(
                      _vm._s(
                        _vm.startCheck && _vm.loading ? "检测中..." : "开始检测"
                      )
                    )
                  ])
            ])
      ]),
      _c(
        "Popup",
        {
          attrs: { round: "" },
          on: {
            close: function($event) {
              _vm.isShowModal = false
            }
          },
          model: {
            value: _vm.isShowModal,
            callback: function($$v) {
              _vm.isShowModal = $$v
            },
            expression: "isShowModal"
          }
        },
        [
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v("请确认已执行以下操作")
            ]),
            _c("div", { staticClass: "tip-text" }, [
              _vm._v("1.控制盒已经连接pcb板；")
            ]),
            _c("div", { staticClass: "tip-text" }, [
              _vm._v("2.面板的三个旋钮已调至中间档位；")
            ]),
            _c("div", { staticClass: "tip-text" }, [
              _vm._v("3.红外对管也被导通；")
            ]),
            _c("div", { staticClass: "tip-text" }, [
              _vm._v("4.近10分钟内有过开关操作；")
            ]),
            _c(
              "div",
              {
                staticClass: "modal-btn",
                on: {
                  click: function($event) {
                    return _vm.onclickStartBtn(1)
                  }
                }
              },
              [_vm._v("开始检测")]
            )
          ])
        ]
      ),
      _c(
        "Popup",
        {
          attrs: { round: "" },
          model: {
            value: _vm.isShowSuccModal,
            callback: function($$v) {
              _vm.isShowSuccModal = $$v
            },
            expression: "isShowSuccModal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-body succ-modal" },
            [
              _c("Icon", { attrs: { name: "checked" } }),
              _c("div", { staticClass: "title" }, [_vm._v("质检成功")]),
              _c("div", { staticClass: "tip-text" }, [
                _vm._v("结果已自动上传并保存,即将退回首页")
              ])
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }