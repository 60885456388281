require("core-js/modules/es.array.concat");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/dialog/style", "vant/es/dialog", "vant/es/popup/style", "vant/es/popup", "vant/es/loading/style", "vant/es/loading", "vant/es/button/style", "vant/es/button", "vant/es/icon/style", "vant/es/icon"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/popup/style"), require("vant/es/popup"), require("vant/es/loading/style"), require("vant/es/loading"), require("vant/es/button/style"), require("vant/es/button"), require("vant/es/icon/style"), require("vant/es/icon"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.dialog, global.style, global.popup, global.style, global.loading, global.style, global.button, global.style, global.icon);
    global.tlQa = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _dialog, _style2, _popup, _style3, _loading, _style4, _button, _style5, _icon) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _dialog = _interopRequireDefault(_dialog);
  _popup = _interopRequireDefault(_popup);
  _loading = _interopRequireDefault(_loading);
  _button = _interopRequireDefault(_button);
  _icon = _interopRequireDefault(_icon);
  var _default = {
    name: 'TlQa',
    components: {
      Icon: _icon.default,
      Button: _button.default,
      Loading: _loading.default,
      Popup: _popup.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost; // qaType: 1控制盒质检，2称重质检
      // isFs: 1代表风送控制盒质检

      var _this$$route$query = this.$route.query,
          uid = _this$$route$query.uid,
          isFs = _this$$route$query.isFs,
          qaType = _this$$route$query.qaType;
      return {
        ossHost: ossHost,
        themeColor: '#209A56',
        uniqueId: uid,
        // 是否获取过设备数据
        loaded: false,
        loading: false,
        info: {},
        // 是否开始检测过
        startCheck: false,
        isShowModal: false,
        isShowSuccModal: false,
        isCheck: false,
        // 震动器是否正常工作
        zdqCheck: false,
        isFs: isFs == 1,
        qaType: Number(qaType) || 1
      };
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var uuid = vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);
      vm.name = name;

      if (fType != 'wangbo') {
        vm.$router.replace({
          path: "404"
        });
        return;
      }

      vm.startCheckAction();
    },
    methods: {
      // 获取设备检测状态
      loadDeviceInfo: function loadDeviceInfo() {
        var vm = this;
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "tl/kzh/qualityCheckStatus?deviceIds=").concat(vm.$route.params.id)
        }).then(function (res) {
          vm.info = res.data[0] || {};
          vm.loaded = true;
          vm.time++;
          vm.checkTime++;

          if (!vm.startCheck) {
            setTimeout(function () {
              vm.loading = false;
            }, 2000);
          } // 通过质检


          if (vm.info.status == 2 && vm.startCheck) {
            clearInterval(vm.clearIntervalId);
            vm.isShowSuccModal = true;
            vm.loading = false;
            setTimeout(function () {
              vm.$router.back();
            }, 3000);
          }

          if (vm.time >= 120 || !vm.info.online) {
            clearInterval(vm.clearIntervalId);
            !vm.info.online && (vm.startCheck = false);
            console.log(vm.info);
            vm.info.online && _dialog.default.alert({
              title: '提示',
              message: "\u8D28\u68C0\u8D85\u65F6\uFF0C\u8BF7\u786E\u8BA4\u5404\u9879\u64CD\u4F5C\u540E\u518D\u91CD\u65B0\u5F00\u59CB\u8D28\u68C0",
              confirmButtonText: '我知道了'
            }).then(function () {
              vm.loading = false;
            });
          }

          if ((vm.info.infraredStatus == 2 || vm.info.motoStatus == 2 || vm.info.openStatus == 2 || vm.info.weighReadStatus == 2) && vm.qaType != 2 || vm.info.weighReadStatus == 2 && vm.qaType == 2 || (vm.info.infraredStatus == 2 || vm.info.motoStatus == 2 || vm.info.openStatus == 2 || vm.info.pressureReadStatus == 2) && vm.isFs) {
            // vm.info.infraredStatus = vm.info.infraredStatus == 2 ? 0 : vm.info.infraredStatus
            // vm.info.motoStatus = vm.info.motoStatus == 2 ? 0 : vm.info.motoStatus
            // vm.info.openStatus = vm.info.openStatus == 2 ? 0 : vm.info.openStatus
            // vm.info.weighReadStatus = vm.info.weighReadStatus == 2 ? 0 : vm.info.weighReadStatus
            // vm.info.pressureReadStatus = vm.info.pressureReadStatus == 2 ? 0 : vm.info.pressureReadStatus
            if (vm.checkTime >= 20) {
              vm.loading = false;
              clearInterval(vm.clearIntervalId);
            }
          }
        }).catch(function (err) {
          console.log(err);
        });
      },
      // 标记当前设备为质检状态
      startCheckAction: function startCheckAction() {
        var vm = this;
        vm.loading = true;
        vm.$http({
          type: "put",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "tl/kzh/qualityCheck"),
          data: {
            deviceIds: [vm.$route.params.id],
            operation: 3,
            userName: vm.name,
            password: vm.$config.base.password,
            // 默认控制盒质检
            type: vm.qaType
          }
        }).then(function () {
          vm.time = 0;
          vm.checkTime = 0;
          clearInterval(vm.clearIntervalId);
          vm.startCheck = true;
          vm.clearIntervalId = setInterval(vm.loadDeviceInfo, 1000);
          vm.info = {};
        });
      },
      // 开始检测
      onclickStartBtn: function onclickStartBtn(type) {
        var vm = this;
        var isShowTlModalTip = localStorage.getItem('isShowTlModalTip'); // 首次会展示提示弹框

        if (!isShowTlModalTip && type != 1) {
          vm.isShowModal = true;
          return;
        } // 点击提示弹框中的开始检测


        if (type == 1) {
          localStorage.setItem('isShowTlModalTip', 1);
          vm.isShowModal = false;
        }

        vm.startCheckAction();
      }
    }
  };
  _exports.default = _default;
});